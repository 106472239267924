<template>
  <div id="user-profile">
    <b-overlay :show="loading">
      <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
        <slot name="navbar">
          <profile-header />
        </slot>
      </b-navbar>
      <div class="container" style="width: 90%">
        <div class="mt-4 mb-4">
          <b-breadcrumb class="breadcrumb-chevron">
            <b-breadcrumb-item :to="{ name: 'paket-selengkapnya' }">
              Paket
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ data.name }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <section id="card-images">
          <b-col>
            <b-row>
              <b-col cols="12">
                <b-card>
                  <b-row>
                    <!-- Foto Paket-->
                    <b-col md="6" xl="3" class="mb-2 mb-md-0">
                      <b-img :src="data.thumbnail ? `https://api-ecourse.digma.id/${data.thumbnail}`: require('@/assets/images/elp/no-image.jpg')" alt="Image detail" style="height: 200px; object-fit: cover; width: 100%" class="img-fluid" />
                    </b-col>
                    <!-- Detail Paket -->
                    <b-col md="6" xl="9">
                      <!-- Nama Paket dan Jumlah yang berlangganan -->
                      <b-row>
                        <b-col lg="12" xl="9">
                          <h2 class="text-left">
                            <b>{{ data.name }}</b>
                          </h2>
                        </b-col>
                        <b-col lg="12" xl="3" class="mb-2 mb-xl-0">
                          <b-badge variant="primary">
                            <feather-icon icon="UsersIcon" />
                          </b-badge>
                          <b-badge variant="light-primary">
                            <span>{{ data.sum_quota }} Berlangganan</span>
                          </b-badge>
                        </b-col>
                      </b-row>
                      <!-- Deskripsi Paket -->
                      <b-row>
                        <b-col>
                          <p class="text-dark text-left">
                            {{
                              data.description
                                ? data.description
                                : "Tidak ada deskripsi"
                            }}
                          </p>
                        </b-col>
                      </b-row>
                      <!-- Harga Paket dan Tombol Langganan -->
                      <b-row class="mt-1 align-items-center">
                        <b-col md="6" xl="3" v-if="is_paket_purchased == 0">
                          <h2 class="text-left">
                            <b>Rp. {{ formatRupiah(data.price) }}</b>
                          </h2>
                        </b-col>
                        <b-col md="6" xl="4">
                          <b-button v-if="is_paket_purchased == 0" variant="primary" class="float-left"
                            :to="{ name: 'payment', params: { id: data.slug } }">
                            Langganan
                          </b-button>
                          <b-button v-else variant="success" class="float-left disabled" disabled>
                            Sudah Dibeli
                          </b-button>
                        </b-col>
                      </b-row>
                      <!-- Info Paket -->
                      <b-row class="mt-2">
                        <b-col>
                          <p class="text-left text-dark">
                            *Langganan paket ini aktif untuk 1 tahun. Berhenti
                            berlangganan kapan saja.
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <!-- Subtest & Keuntungan + Pertanyaan Umum-->
              <b-col sm="12" md="6" class="text-left">
                <b-card header="Subtest" header-bg-variant="primary" header-text-variant="white">
                  <app-collapse class="mt-1" v-if="dataSubtes[0] != null">
                    <div class="" v-for="(item, i) in dataSubtes" :key="i">
                      <app-collapse-item :title="item.title" v-if="item.title">
                        <ul style="padding: 0">
                          <li v-for="(items, i) in item.items" :key="i" class="mt-1">
                            <b>{{ items.title }}</b>
                            <br />
                            <feather-icon icon="ClockIcon" class="mr-50" />
                            <small class="font-weight-bold">{{ items.timer }} Menit</small>
                            <feather-icon icon="FileTextIcon" class="mr-50 ml-2" />
                            <small class="font-weight-bold">{{ items.sum_soal }} Soal</small>
                          </li>
                        </ul>
                      </app-collapse-item>
                      <ul class="list-unstyled" v-else>
                        <b-media tag="li">
                          <template #aside>
                            <b-img rounded="circle" blank blank-color="#26292d" width="10" alt="placeholder"></b-img>
                          </template>
                          <h5 class="mt-0">
                            <b>{{ item.title }}</b>
                          </h5>
                          <p class="mb-2">
                            <b-badge variant="light-secondary">
                              <feather-icon icon="ClockIcon" class="mr-25" />
                              <span class="ml-25">{{ item.timer }} menit</span>
                            </b-badge>
                            <b-badge variant="light-secondary" class="ml-25">
                              <feather-icon icon="ClipboardIcon" class="mr-25" />
                              <span class="ml-25">
                                {{ item.sum_soal }} Soal
                              </span>
                            </b-badge>
                          </p>
                        </b-media>
                      </ul>
                    </div>
                  </app-collapse>
                  <div v-else>
                    <h5 class="text-danger text-center mt-2">
                      Tidak ada Subtest
                    </h5>
                  </div>
                </b-card>

                <div class="container mt-5">
                  <div class="card">
                    <div class="card-body">
                      <!-- Card Header with Title and Dropdown Toggle -->
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center" @click="toggleCollapse" style="cursor: pointer;">
                          <i :class="{'fas fa-chevron-down': !isCollapseOpen, 'fas fa-chevron-up': isCollapseOpen}"></i>
                          <h5 class="card-title mb-0" style="margin-left: 20px; font-size: medium">
                            JavaScript Beginning
                          </h5>
                        </div>
                        <span class="btn btn-link text-decoration-none" @click="toggleCollapse">
                          <span>1 Materi</span>
                        </span>
                      </div>
                      <!-- Collapse with Transition -->
                      <transition name="collapse">
                        <div v-if="isCollapseOpen" id="videoList">
                          <ul class="list-group list-group-flush mt-1">
                            <li class="list-group-item px-2">
                              <a href="#"
                                class="d-flex justify-content-between align-items-center text-inherit text-decoration-none">
                                <div class="text-truncate">
                                  <span class="icon-shape bg-light text-secondary icon-sm rounded-circle me-2">
                                    <i class="fas fa-lock"></i>
                                  </span>
                                  <span class="ml-1">Introduction</span>
                                </div>
                                <div class="text-truncate">
                                  <span>1m 41s</span>
                                  <span><i class="fa-solid fa-video ml-1"></i></span>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>

              </b-col>
              <b-col sm="12" md="6">
                <b-card header="Keuntungan" header-bg-variant="primary" header-text-variant="white">
                  <div v-if="data.advantages[0] != null">
                    <p class="mt-2" v-for="(item, i) in data.advantages" :key="i">
                      <feather-icon icon="CheckCircleIcon" size="24" class="mr-25 text-success" />
                      <span class="ml-25">{{ item.text }}</span>
                    </p>
                  </div>
                  <div v-else>
                    <h5 class="text-danger text-center mt-2">Tidak ada</h5>
                  </div>
                </b-card>

                <b-card header="Pertanyaan Umum" header-bg-variant="primary" header-text-variant="white">
                  <div v-if="data.faqs[0] != null">
                    <app-collapse class="mt-1" type="margin">
                      <app-collapse-item v-for="(faq, i) in data.faqs" :key="i" :title="faq.question">
                        <div v-html="faq.answer" />
                      </app-collapse-item>
                    </app-collapse>
                  </div>
                  <div v-else>
                    <h5 class="text-danger text-center mt-2">Tidak ada</h5>
                  </div>
                </b-card>

              </b-col>
            </b-row>

          </b-col>

        </section>
      </div>
      <footer-home />
    </b-overlay>
  </div>
</template>

<script>
  import {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BBadge,
    BCollapse,
    VBToggle,
    BAlert,
    BMedia,
    // BPill,
  } from "bootstrap-vue";

  import ProfileHeader from "./components/Navbar.vue";
  import FooterHome from "./components/Footer.vue";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BOverlay,
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,
      BButton,
      BBreadcrumb,
      BBreadcrumbItem,
      BImg,
      BBadge,
      BCollapse,
      VBToggle,
      BAlert,
      BMedia,
      // BPill,
      AppCollapse,
      AppCollapseItem,
      FooterHome,
      ProfileHeader,
    },

    data() {
      return {
        profileData: {},
        data: {},
        dataSubtes: [],
        loading: false,
        id: this.$route.params.id,
        is_paket_purchased: 0,
        isCollapseOpen: false,
      };
    },
    directives: {
      "b-toggle": VBToggle,
    },

    computed: {
      collapseIconClass() {
        return this.isCollapseOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down';
      }
    },

    methods: {
      getData() {
        this.loading = true;
        let id = this.id;
        this.$store
          .dispatch("paketTryout/detail", {
            id
          })
          .then((res) => {
            this.loading = false;
            this.data = res.data;
            this.$store.commit("paketTryout/SET_DETAIL", res.data);
            this.getSubtes();
            if (this.user) {
              this.getDataPurchase();
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      },
      getSubtes() {
        let params = {
          paket_id: this.data.id
        };
        this.$store
          .dispatch("paketTryout/indexSubtestPaket", params)
          .then((res) => {
            this.dataSubtes = res.data.data;
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      },
      getDataPurchase() {
        let params = {
          paket_id: this.data.id
        };
        this.$store
          .dispatch("paketTryout/cekPurchase", params)
          .then((res) => {
            this.is_paket_purchased = res.data.is_paket_purchased;
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            });
          });
      },
      toggleCollapse() {
        this.isCollapseOpen = !this.isCollapseOpen;
      },
    },

    created() {
      this.getData();
    },
  };
</script>


<style lang="scss">
  @import "@core/scss/vue/pages/page-profile.scss";

  li.mt-1 {
    border-bottom: 3px solid #e83b07;
    padding-bottom: 10px;
    list-style: none;
  }

  .collapse-enter-active,
  .collapse-leave-active {
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    overflow: hidden;
  }

  .collapse-enter,
  .collapse-leave-to {
    max-height: 0;
    opacity: 0;
  }

  .collapse-enter-to,
  .collapse-leave {
    max-height: 200px;
    /* Adjust based on your content's expected height */
    opacity: 1;
  }

  /* Optional: Adjust padding and margin to ensure smooth appearance */
  .collapse-content {
    padding: 10px;
    /* Adjust as needed */
  }
</style>